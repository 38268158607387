import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/index";
import About from "./pages/about";
import SignUp from "./pages/signup";
import UserHome from "./pages/UserHome";
import Login from "./pages/login";
import ApprovePayment from "./pages/approvePayment";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/home" element={<UserHome />} />
        <Route path="/payment/verify" element={<ApprovePayment />} />
        <Route path="/sign-up" element={<SignUp />}/>
        <Route path="/login" element={<Login />}/>
      </Routes>
    </Router>
  );
}

export default App;
