// src/config.js
const config = {
  baseURL_BE: process.env.NODE_ENV === 'production'
  ? 'https://xwiped.com:8443'
  : 'http://127.0.0.1:8000',
  baseURL_FE: process.env.NODE_ENV === 'production'
  ? 'https://xwiped.com'
  : 'http://localhost:3000'
};
  
  export default config;
