import React from "react";
import ThreeScene from './components/ThreeScene';
import SignupLoginButtons from './components/SignupLoginButtons';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup_Login_Buttons from "./components/SignupLoginButtons";

const Home = () => {
    return (
        <div className="bg-dark position-relative vh-100 d-flex justify-content-center align-items-center">
            <ThreeScene />
            <SignupLoginButtons />
        </div>
    );
};

export default Home;

