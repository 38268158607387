import React from 'react';
import RandomStringGenerator from './components/RandomStringGenerator';
import MatrixScreensaver from './components/MatrixScreensaver';

const SignUp = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-dark text-white">
      <RandomStringGenerator />
      <MatrixScreensaver />
    </div>
  );
};

export default SignUp;
