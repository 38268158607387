// src/RandomStringGenerator.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const RandomStringGenerator = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isCallingApi, setIsCallingApi] = useState(false); // Track if an API call is in progress
  const maxRetries = 5;

  const generateRandomStrings = () => {
    const array1 = new Uint32Array(10);
    const array2 = new Uint32Array(10);
    window.crypto.getRandomValues(array1);
    window.crypto.getRandomValues(array2);

    const randomStringId = Array.from(array1).map(num => num.toString(36)).join('').slice(0, 32);
    const randomPw = Array.from(array2).map(num => num.toString(36)).join('').slice(0, 32);

    return { stringId: randomStringId, pw: randomPw };
  };

  const callApi = async ({ stringId, pw }, retries = 0) => {
    if (isCallingApi) return; // Prevent multiple parallel calls
    setIsCallingApi(true); // Set the flag to indicate the API call is in progress

    try {
      console.info("going to create user... 1");
      const response = await fetch(`${config.baseURL_BE}/v1/users/?vx=asdfasdf`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ string_id: stringId, password: pw }),
      });

      if (response.ok) {
        localStorage.setItem('stringId', stringId);
        localStorage.setItem('pw', pw);
        console.info("wrote to storage... 4");
        navigate('/home');
      } else {
        throw new Error('API call failed');
      }
      console.info("done... 5");
    } catch (error) {
      console.error(error);
      if (retries < maxRetries) {
        const newStrings = generateRandomStrings();
        console.info("going to retry as a catch ... 2");
        callApi(newStrings, retries + 1); // Retry with new random strings if it fails
      } else {
        setError('Failed to generate and save random strings after 5 attempts.');
      }
    } finally {
      setIsCallingApi(false); // Reset the flag after the API call is complete
    }
  };

  useEffect(() => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (storedStringId && storedPw) {
      navigate('/home'); // Navigate to /home if strings exist in local storage
    } else {
      const randomStrings = generateRandomStrings();
      console.info("here in the else ... 3");
      callApi(randomStrings);
    }
  }, []);

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h1>Secure Random String Generator</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default RandomStringGenerator;
